import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword, sendPasswordResetEmail, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  Stack,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors } from "../lib/theme";
import { auth } from "../../shared/firebase";
import { useAuth } from "../../shared/useAuth";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MemoryIcon from "@mui/icons-material/Memory";
import { Helmet } from "react-helmet";
import ContainerBox from "../../shared/ContainerBox";
import AlertDialog from "../../shared/AlertDialog";
import { Link } from "react-router-dom";
import GoogleIcon from '@mui/icons-material/Google';

const GetTheApp = () => (
  <Container maxWidth="sm">
    <Box>
      <Paper
        elevation={3}
        sx={{
          padding: "15px 30px",
          background: `linear-gradient(45deg, ${colors.gold} 30%, ${colors.white} 90%)`,
          borderRadius: 2,
          boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        }}
      >
        <Stack spacing={3} alignItems="center">
          <PhoneIphoneIcon sx={{ fontSize: 60, color: colors.navy }} />
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            align="center"
            sx={{ color: colors.navy, fontWeight: "bold" }}
          >
            Soon on iPhone!
          </Typography>
          <Typography
            variant="h6"
            align="center"
            sx={{ color: colors.navy }}
            style={{ marginTop: "0.5rem" }}
          >
            The iOS app is days away!
          </Typography>
          <Button
            variant="contained"
            size="large"
            startIcon={<MemoryIcon />}
            href="https://apps.apple.com/app/golden-record/id6501951254"
            target="_blank"
            rel="noopener noreferrer"
            disabled
            sx={{
              bgcolor: "#8B6B00",
              color: colors.white,
              fontWeight: "bold",
              textTransform: "none",
              fontSize: "1.1rem",
              padding: "12px 24px",
              letterSpacing: "1px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                bgcolor: "#6B5200",
                color: colors.white,
                boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
              },
            }}
          >
            Install iOS app
          </Button>
          <Typography
            variant="body2"
            align="center"
            sx={{ color: colors.navy, mt: 2 }}
          >
            Start preserving your precious memories today!
          </Typography>
        </Stack>
      </Paper>
    </Box>
  </Container>
);

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [fromQuestionnaire, setFromQuestionnaire] = useState(false);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [redirectTo, setRedirectTo] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  useEffect(() => {
    const from = new URLSearchParams(window.location.search).get("from");
    if (from === "questionnaire") {
      setFromQuestionnaire(true);

      // Redirect for null questionnaire data
      const questionnaireData = JSON.parse(
        localStorage.getItem("questionnaireData")
      );

      if (!questionnaireData) {
        navigate("/questionnaire");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const redirect = new URLSearchParams(window.location.search).get("redirect_to");
    if (redirect) {
      setRedirectTo(redirect);
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const lowercaseEmail = email.toLowerCase();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        lowercaseEmail,
        password
      );
      localStorage.setItem("user", JSON.stringify({...userCredential.user, email: lowercaseEmail}));
      const redirect_to = new URLSearchParams(window.location.search).get("redirect_to");
      window.location.href = redirect_to || (fromQuestionnaire ? "/checkout" : "/dashboard");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin(event);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError("Please enter your email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setError(null);
      setDialogMessage("Password reset email sent. Please check your inbox.");
      setOpenDialog(true);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      localStorage.setItem("user", JSON.stringify({...user, email: user.email.toLowerCase()}));
      window.location.href = redirectTo || (fromQuestionnaire ? "/checkout" : "/dashboard");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box>
      <Helmet>
        <title>Login - Golden Record</title>
        <meta name="description" content="Log in to your Golden Record account" />
      </Helmet>
      <Typography variant="h3" sx={{ color: colors.gold, mb: 0 }}>
        Let's get you logged in!
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: colors.white, pt: 1, fontSize: "1.3rem" }}
      >
        Enter your email and password to login to your account.
        {" "}
        {!fromQuestionnaire && (
          <Link
            to={redirectTo ? `/register?redirect_to=${encodeURIComponent(redirectTo)}` : "/register"}
            style={{ color: colors.gold }}
          >
            Don't have an account? Register here.
          </Link>
        )}
      </Typography>

      <ContainerBox>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom>
                  Log in
                </Typography>
                <form onSubmit={handleLogin}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    onKeyPress={handleKeyPress}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    required
                  />
                  {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                      {error}
                    </Typography>
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Log in
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleForgotPassword}
                    >
                      Forgot Password?
                    </Button>
                  </Box>
                </form>
                <Divider sx={{ my: 2 }}>OR</Divider>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                  onClick={handleGoogleLogin}
                  sx={{ mt: 2 }}
                >
                  Sign in with Google
                </Button>
              </Paper>
            </Grid>
            {fromQuestionnaire ? (
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 4,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    No account? No problem.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    You will be able to create your account in the next step.
                    Simply click the button below to continue.
                  </Typography>
                  <Button
                    variant="emphasized"
                    size="large"
                    onClick={() => navigate("/checkout")}
                  >
                    Continue as Guest
                  </Button>
                </Paper>
              </Grid>
            ) : (
              <Grid item xs={12} md={6}>
                <GetTheApp />
              </Grid>
            )}
          </Grid>
        </Container>
      </ContainerBox>
      <AlertDialog
        open={openDialog}
        title="Password Reset"
        content={dialogMessage}
        onClose={handleCloseDialog}
        okText="Close"
      />
    </Box>
  );
};

export default Login;
